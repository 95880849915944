import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import PlansNav from '../../components/nav/PlansNav'
import MedicareContent from '../../components/data/MedicareContent'
import cursorsecondaryicn from "../../assets/img/cursor-secondary-icn.svg";

const UVMHealthAdvantage = ({ location }) => (
	<Layout location={location} heading1="Medicare Advantage Plans" heading2="Find the right plan for your needs and lifestyle.">
    	<Seo title="Medicare Advantage Plans" meta={[{description: 'Choose from Medicare Advantage plans as low as $0 per month.'}]} bodyclass="plans subpage" />
		
		<PlansNav />

		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">
					<main>
						{/* <h3>Benefits designed with you&mdash;to meet your needs and your budget</h3>
						<p>UVM Health Advantage was inspired by what people from across Vermont and northern New York told us they want in a Medicare plan.</p>
						<p><a href="#plansgrid" class="anchor">See plan details below</a></p>

						<h3 class="small">Save on health expenses so you have more to spend on the things you enjoy.</h3>
						<ul class="lrg-iconlist">
							<li><img src={dollartertiaryicn} width="97" height="97" alt="" /> <span>Monthly premiums starting at <strong>$0 per month</strong></span></li>
							<li><img src={dentaltertiaryicn} width="97" height="97" alt="" /> <span>Up to <strong>$1,000 for dental benefits</strong> that go beyond routine checkups and include fillings and crowns</span></li>
							<li><img src={visiontertiaryicn} width="97" height="97" alt="" /> <span>Up to <strong>$225 per year for eyewear</strong></span></li>
							<li><img src={hearingtertiaryicn} width="97" height="97" alt="" /> <span>Up to <strong>$1,000 for hearing aids</strong> every 3 years</span></li>
							<li><img src={medicationtertiaryicn} width="97" height="97" alt="" /> <span>Up to <strong>$140 per year to spend on over-the-counter medicine and health-related items</strong></span></li>
							<li><img src={wellbeingtertiaryicn} width="97" height="97" alt="" /> <span>Up to <strong>$200 in WellBeing Rewards</strong> for taking part in healthy activities</span></li>
						</ul> */}

						{ /* <MedicareContent route="getuvmplans/v1/pid/2022" /> */ }

						<div>
							<h3 class="small">Choose from Medicare Advantage plans as low as $0 per month.</h3>

							<table class="plangrid-table" id="plansgrid">
								<caption class="visuallyhidden">Available UVM plans</caption>
								<tbody>
									<tr class="select">
										<td class="planname">UVM Health Advantage <strong>Select</strong> (PPO)</td>
										<td class="plancost"><strong>$0</strong> per month<br/>with prescription drug (Part D)</td>
										<td class="detaillink"><Link to="/plans/uvm-health-advantage/uvm-health-advantage-select/" class="arrow black">View details</Link></td>
									</tr>
									<tr class="secure">
										<td class="planname">UVM Health Advantage <strong>Secure</strong> (PPO)</td>
										<td class="plancost"><strong>$53.90</strong> per month<br/>with prescription drug (Part D)</td>
										<td class="detaillink"><Link to="/plans/uvm-health-advantage/uvm-health-advantage-secure/" class="arrow black">View details</Link></td>
									</tr>
									<tr class="preferred">
										<td class="planname">UVM Health Advantage <strong>Preferred</strong> (PPO)</td>
										<td class="plancost"><strong>$127.40</strong> per month<br/>with prescription drug (Part D)</td>
										<td class="detaillink"><Link to="/plans/uvm-health-advantage/uvm-health-advantage-preferred/" class="arrow black">View details</Link></td>
									</tr>
								</tbody>
							</table>
							
							<p><a rel="noopener noreferrer" href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents/aep/2024/uvm/mvp-health-care-medicare-benefits-at-a-glance-uvmha-2024.pdf">View a side-by-side plan comparison (PDF)</a></p>
						</div>

						<h3 className="small">Get the care you need &ndash; and save money &ndash; with valuable benefits, including:</h3>
						<ul>
							<li>Up to <strong>$2,000 for dental benefits</strong> that go beyond routine checkups and including fillings, crowns, and more</li>
							<li>Up to <strong>$225 per year for eyewear</strong></li>
							<li>Up to <strong>$140 per year to spend on over-the-counter medicine and health-related items</strong></li>
							<li>Up to <strong>$999 per hearing aid</strong></li>
							<li>Up to <strong>44 free rides</strong> to or from medical appointments</li>
						</ul>
						<p>Plus, get rewarded for focusing on your health with the <em>Be Well</em> Rewards Program. Earn a $100 gift card reward for seeing your doctor for an Annual Wellness Visit. This appointment helps you keep up with preventive screenings, review your overall physical and mental health and discuss any other health needs. Your Annual Wellness Visit is covered at no cost under your plan.</p>

						<div className="ctas secondary">
							<div className="flexwrap">
								<div className="secondary-cta">
									<img src={cursorsecondaryicn} alt="" width="60" height="60" className="icon" />
									<p className="head"><b>Get a personalized plan recommendation!</b></p>
									<p>Our interactive plan recommendation tool makes it easy to find the right UVM Health Advantage plan for your needs. Simply answer a few questions about your health care situation &ndash; like your current prescriptions, preferred pharmacy, and expected health care needs &ndash; and a plan recommendation will be made just for you.</p>
									<a href="https://bestplanforme.mvphealthcare.com/?sourcesite=uvm" className="button external_button" target="_blank" rel="noreferrer">Let&rsquo;s Get Started</a>
								</div>
							</div>
						</div>

						<div className="linkcard">
							<Link to="/plans/benefits-savings-support/">Learn more about these and other Benefits, Savings and Support.</Link>
						</div>

						{/*
						<h3 class="small">Choose from plans as low as $0 per month.</h3>
						<p><b>Below are the Medicare Advantage Plans available:</b></p>

						<table class="plangrid-table" id="plansgrid">
							<caption class="visuallyhidden">Available UVM plans</caption>
							<tr class="select">
								<td class="planname">UVM<sup>&reg;</sup> Health Advantage <strong>Select</strong> (PPO)</td>
								<td class="plancost"><strong>$0</strong><br />per month with Part D</td>
								<td class="detaillink"><Link to="/plans/uvm-health-advantage/uvm-health-advantage-select/" className="arrow black">View details</Link></td>
							</tr>
							<tr class="secure">
								<td class="planname">UVM<sup>&reg;</sup> Health Advantage <strong>Secure</strong> (PPO)</td>
								<td class="plancost"><strong>$50</strong><br />per month with Part D</td>
								<td class="detaillink"><Link to="/plans/uvm-health-advantage/uvm-health-advantage-secure/" className="arrow black">View details</Link></td>
							</tr>
							<tr class="preferred">
								<td class="planname">UVM<sup>&reg;</sup> Health Advantage <strong>Preferred</strong> (PPO)</td>
								<td class="plancost"><strong>$130</strong><br />per month with Part D</td>
								<td class="detaillink"><Link to="/plans/uvm-health-advantage/uvm-health-advantage-preferred/" className="arrow black">View details</Link></td>
							</tr>
						</table>
						<p><a href="##" class="download">View a side-by-side plan comparison (PDF)</a></p>
						*/}
					</main>
				</div>
			</div>
		</div>
	</Layout>
)

export default UVMHealthAdvantage
